import * as React from 'react';
import Layout from '../Components/Layout/Layout';
import About from '../Components/About/About';
import Goals from '../Components/Goals/Goals';
import SEO from '../Components/seo';

// markup
const AboutPage = () => (
  <>
    <SEO title="About" />
    <Layout page="About">
      <main id="main">
        <About />
        <Goals />
      </main>
    </Layout>
  </>

);

export default AboutPage;
