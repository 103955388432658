import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import './about.css';

const About = () => (
  <section id="about">
    <div className="container">
      <div className="row">
        <div className="col-lg-6 about-img">
          <StaticImage src="../../images/about/objective.svg" alt="Main objective illustration" />
        </div>

        <div className="col-lg-6 content">
          <h2>Main Objective</h2>
          <p>
            To create sustainable change and impact that will drive success
            in the lives of young girls and women.
            SheIsBrave strives to increase the capacity and the
            ability to bounce back into purpose, end
            period poverty, and promote the rights of girls and women.
          </p>

        </div>
      </div>
      <div className="row">
        <div className="col-lg-6 about-img order-lg-2">
          <StaticImage src="../../images/about/mission.svg" alt="Mission illustration" />
        </div>
        <div className="col-lg-6 content lg-order-lg-1">
          <h2>Mission</h2>
          <p>
            {' '}
            To empower young women and girls to unleash
            bravery and their full potential,
            to encourage ambition confidence and strengthen
            their characters, to have the ability to bounce back.
          </p>
        </div>

      </div>
      <div className="row">
        <div className="col-lg-6 about-img">
          <StaticImage src="../../images/about/vision.svg" alt="Vision illustration" />
        </div>

        <div className="col-lg-6 content">
          <h2>Vision</h2>
          <p>
            {' '}
            A platform in our world where every young girl and women
            have the right mentors, demonstration of sisterhood.
            Where women and girls become the best version of
            themselves not to see themselves as victims
            but brave victors who overcame and can overcome.
          </p>

        </div>
      </div>

    </div>
  </section>
);

export default About;
